<!--
 * @Author: zxf
 * @Date: 2022-09-13 10:45:06
 * @LastEditors: zxf
 * @LastEditTime: 2023-09-08 11:02:35
 * @Description: file content
-->
<template>
  <div class="u-svg-text">
    <svg
      width="176px"
      height="21px"
      viewBox="0 0 176 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>{{ innerText }}</title>
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
        >
          <stop
            stop-color="#FFFFFF"
            offset="0%"
          />
          <stop
            stop-color="#EFF6FC"
            offset="47.3052562%"
          />
          <stop
            stop-color="#3088D4"
            offset="100%"
          />
        </linearGradient>
      </defs>
      <g
        id="页面-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        font-family="PingFangSC-Semibold, PingFang SC"
        font-size="22"
        font-weight="500"
      >
        <g
          id="机器人平台UI"
          transform="translate(-57.000000, -382.000000)"
          fill="url(#linearGradient-1)"
          fill-rule="nonzero"
        >
          <g
            id="编组-7备份"
            transform="translate(14.629327, 377.500000)"
          >
            <text :id="innerText">
              <tspan
                x="42.3706731"
                y="23"
              >{{ innerText }}</tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ScreenTitle',
  props: {
    innerText: {
      type: String,
      default: '智慧机器人平台'
    }
  }
};
</script>

<style>
.u-svg-text{
  display: inline-block;
}
</style>
