<template>
  <div class="l-virtual">
    <div
      class="o-flicker"
      :class="{
        'o-flicker--open': omeMusicStatus==='open'
      }"
    >
      <div class="o-flicker__item o-flicker__item--top" />
      <div class="o-flicker__item o-flicker__item--bottom" />
    </div>

    <audio
      id="bjs"
      ref="omeMusic"
      loop="loop"
      muted="muted"
      class="o-omen-audio"
      :src="$config.omenMusicSrc.warning"
    />
    <!-- 头部 Start -->
    <div
      class="l-virtual__header"
    >
      <screen-title :inner-text="screenTitle" />
    </div>
    <!-- 头部 End -->

    <!-- 机器人3D Start -->
    <canvas-box
      class="l-canvas-wrap"
      @showCallback="handleSwitchChartStatus"
    />
    <npl
      ref="Npl"
      class="l-canvas-wrap o-zindex"
    />
    <!-- <canvas-npl
      v-show="showNpl"
      class="l-canvas-wrap o-zindex"
      @showCallback="handleSwitchChartStatus"
    /> -->
    <!-- 机器人3D End -->

    <!-- 左侧图表 Start -->
    <transition
      appear
      :duration="{ enter: 1000, leave:1400 }"
      name="animate__animated animate__bounceInLeft"
      enter-active-class="animate__animated animate__bounceInLeft"
      leave-active-class="animate__animated animate__bounceOutLeft"
    >
      <chart-left-box
        v-show="showChart"
        class="l-chart-wrap l-chart-wrap--left"
        @getSonDate="getSonDate "
      />
    </transition>
    <!-- 左侧图表 End -->

    <!-- 右侧图表 Start -->
    <transition
      appear
      :duration="{ enter: 1000, leave:1400 }"
      name="animate__animated animate__bounceInRight"
      enter-active-class="animate__animated animate__bounceInRight"
      leave-active-class="animate__animated animate__bounceOutRight"
    >
      <chart-right-box
        v-show="showChart"
        :code="code"
        class="l-chart-wrap l-chart-wrap--right"
      />
    </transition>
    <!-- 右侧图表 End -->

    <!-- 底部信息 Start -->
    <transition
      appear
      :duration="{ enter: 1200, leave:2800 }"
      name="animate__animated animate__bounceInUp"
      enter-active-class="animate__animated animate__bounceInUp"
      leave-active-class="animate__animated animate__bounceOutDown"
    >
      <bottom-box
        v-show="showChart"
        :code="code"
        class="l-bfooter"
      />
    </transition>
    <!-- 底部信息 End -->
  </div>
</template>

<script>
import { virtualService } from './virtual.service';
import ScreenTitle from './components/ScreenTitle.vue';
// import CanvasBox from './canvasBox.vue';
import CanvasBox from './virtual.vue';
// import CanvasNpl from './canvasNpl.vue';
import ChartLeftBox from './chart/leftBox.vue';
import ChartRightBox from './chart/rightBox.vue';
import BottomBox from './bottomBox/index.vue';
import Npl from './npl/npl-index.vue';
import EventBus from './../../event-bus';

export default {
  name: 'VirtualScreen',
  components: {
    ScreenTitle,
    CanvasBox,
    // CanvasNpl,
    Npl,
    ChartLeftBox,
    ChartRightBox,
    BottomBox
  },
  data () {
    return {
      screenTitle: '智慧消防机器人',
      showChart: true, // 显示图表信息
      showHeader: true, // 显示头部信息
      code: '', // 联网单位编码
      bjxx: [], // 报警信息

      omeMusicStatus: 'close'
    };
  },
  methods: {
    /**
     * @description: 切换图表信息的显示状态：显示/不显示
     * @return {*}
     */
    handleSwitchChartStatus () {
      this.showChart = !this.showChart;
    },
    // 子组件的获取联网单位
    getSonDate (_code) {
      this.code = _code;
      const _this = this;
      console.log('index' + _code);
      this.getHjxx();

      this.intervalGetData = setInterval(function () {
        _this.getHjxx();
      }, 1 * 60 * 1000);
    },
    initScreen () {
      document.getElementById('#app');
    },
    /**
     * @description: 关闭报警声音
     * @return {*}
     */
    handleCancleOme () {
      this.omeMusicStatus = 'close';
      this.$refs.omeMusic.pause();
      this.$refs.omeMusic.currentTime = 0.0;
    },
    /**
     * @description: 开启声音报警
     * @return {*}
     */
    handleOpenOme () {
      const _this = this;
      // const h = _this.$createElement;
      if (_this.omeMusicStatus === 'close') {
        // 开启
        _this.$refs.omeMusic.play().then(() => {
          // autoplay success
          _this.omeMusicStatus = 'open';
        }).catch(() => {
          // _this.$alert('提示', {
          //   title: '提示',
          //   message: h('div', [
          //     h('p', '浏览器默认不允许播放音频，请前往配置以便后续默认支持音频播放，否则仅运行本次播放！'),
          //     h('a', { attrs: { class: 'u-text-primary u-pointer u-pd-t-4 u-inline-block', href: process.env.BASE_URL + 'omeSetIntro.html', target: '_blank' } }, '配置说明')
          //   ]),
          //   confirmButtonText: '本次允许',
          //   type: 'warning'
          // })
          //   .then(() => {
          //     _this.$refs.omeMusic.play();
          //     _this.omeMusicStatus = 'open';
          //   }).catch(() => {
          //   });
        });
      } else {
        // 从头开始播放
        _this.$refs.omeMusic.currentTime = 0.0;
      }
    },
    /**
     * 实时获取是否有报警信息
     */
    getHjxx () {
      virtualService.getHjxx(this.code)
        .then(rsp => {
          this.bjxx = rsp.data;
          console.log(this.bjxx);
          if (this.bjxx.length > 0) {
            this.omeMusicStatus = 'open';
          } else {
            this.omeMusicStatus = 'close';
          }
        })
        .catch(err => {
          console.error('获取消防维保情况信息错误！', err);
        });
    }
  },
  mounted () {
    this.initScreen();
    // 增加快捷键设置
    document.addEventListener('keypress', (event) => {
      if (event.code === 'Digit1') {
        // 数字键1
        this.handleSwitchChartStatus();
      }
      if (event.code === 'Digit2') {
        // 数字键2
        this.$refs.Npl.listen();
      }
      if (event.code === 'Digit3') {
        // 数字键2
        this.$refs.Npl.change();
      }
      if (event.code === 'Digit4') {
        // 数字键4
        if (this.omeMusicStatus === 'close') {
          this.handleOpenOme();
        } else {
          this.handleCancleOme();
        }
      }
    });
    // 监听EventBus触发事件
    /* 调用方式：
      1、 EventBus.$emit('nplListen', params);
      2、window.EventBus.$emit('nplListen', params);
    */
    EventBus.$on('nplListen', param => {
      console.log('EventBus事件触发，入参：', param);
      this.$refs.Npl.listen();
    });
  },
  destroyed () {
    clearInterval(this.intervalGetData);
  }
};
</script>

<style lang="scss">
$--header-h: 70px;
$--header-h2: 190px;
$--chart-left-w: 416px; // 21.7%
$--chart-right-w: 446px; // 25.3%
$--bottom-w: 1020px;

.l-virtual{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;

  .u-font-PFSC {
    font-family: PingFangSC-Semibold;
  }

  .u-font-PFSC-r {
    font-family: PingFangSC-Regular;
  }
}

.l-virtual__header{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1115;
  width: 100%;
  height: $--header-h2;
  line-height: 96px;
  text-align: center;
  background: url('/images/screen/header-bg.png') no-repeat center center;
  background-size: cover;

  /* &::before{
    content: "";
    display: inline-block;
    width: 468px;
    height: 40px;
    background: url('/images/screen/title.svg') no-repeat center center;
  } */
}

.l-canvas-wrap{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  &.o-zindex{
    top: $--header-h;
    z-index: 1114;
  }
}

.l-chart-wrap{
  position: absolute;
  z-index: 1112;
  box-sizing: border-box;
  overflow: hidden;
  padding: 28px 20px 28px 14px;
  color: #fff;
  background-image: linear-gradient(
    269deg,
    rgba(14, 33, 70, 0.24) 0%,
    rgba(12, 29, 63, 0.64) 10%,
    rgba(0, 9, 28, 0.72) 99%
  );

  &--left{
    top: $--header-h;
    bottom: 0;
    left: 0;
    width: $--chart-left-w;
  }

  &--right{
    top: $--header-h;
    right: 0;
    bottom: 0;
    width: $--chart-right-w;
  }
}

.l-bfooter{
  position: absolute;
  bottom: 10px;
  left: $--chart-left-w + 20;
  // left: 0;
  z-index: 14;
  box-sizing: border-box;
  width: $--bottom-w;
  height: 280px;
  color: #fff;
}

@keyframes redPulse {
  from {
    box-shadow: 0 0 40px 20px #d84f28;
    background-color: #d84f28;
  }

  25% {
    box-shadow: 0 0 90px 40px #e0461b;
    background-color: #e0461b;
  }

  50% {
    box-shadow: 0 0 140px 60px #e33100;;
    background-color: #e33100;
  }

  75% {
    box-shadow: 0 0 90px 40px #e0461b;
    background-color: #e0461b;
  }

  to {
    box-shadow: 0 0 40px 20px #d84f28;
    background-color: #d84f28;
  }
}

.o-flicker__item{
  display: none;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 9999;
  height: 0;

  &--top{
    top: 0;
  }

  &--bottom{
    bottom: -20px;
  }
}

.o-flicker--open .o-flicker__item{
  display: block;
  animation: redPulse 2.5s infinite;
}

</style>
