<template>
  <div class="l-screen__chart">
    <div class="c-schart c-srobot-wrap">
      <div class="c-schart__header u-font-PFSC">
        <sub-title inner-text="机器人监控室" />
      </div>
      <div class="c-schart__body">
        <div
          v-for="roomItem in monitoringRoomList"
          :key="`roomItem_${roomItem.code}`"
          class="c-srobot"
          :class="{'c-srobot--active': activeMonitoringRoom===roomItem.code}"
        >
          <i
            class="c-srobot__icon"
            @click="handOnChang(roomItem.code)"
          />
          <div class="c-srobot__inner">
            <div class="c-srobot__title u-font-PFSC">
              {{ roomItem.name }}
            </div>
            <div class="c-srobot__body">
              <!-- <div class="c-srobot__label">
                累计运行时长
              </div> -->
              <div class="c-srobot__num">
                {{ roomItem.yxsc || 0 }}
                <span class="c-srobot__extra">小时</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-schart c-stable-wrap">
      <div class="c-schart__header u-font-PFSC">
        <sub-title inner-text="消防设备" />
      </div>
      <div class="c-schart__body">
        <el-table
          class="c-stable"
          style="width: 100%;"
          height="276px"
          :data="xtsbList"
        >
          <el-table-column
            prop="xtMc"
            label="系统名称"
            width="120"
            header-align="center"
          />
          <el-table-column
            prop="zs"
            label="总数"
            width="70"
            header-align="center"
            align="center"
          />
          <el-table-column
            prop="zc"
            label="正常"
            width="70"
            header-align="center"
            align="center"
          />
          <el-table-column
            prop="yc"
            label="异常"
            width="54"
            header-align="center"
            align="center"
          >
            <template
              slot-scope="scope"
            >
              <span class="u-text-danger">{{ scope.row.yc }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lx"
            label="离线"
            width="54"
            header-align="center"
            align="center"
          />
        </el-table>
      </div>
    </div>
    <div class="c-schart c-sli-wrap">
      <div class="c-schart__header u-font-PFSC">
        <sub-title inner-text="消防维保情况" />
      </div>
      <div class="c-schart__body">
        <ul class="c-sli">
          <li class="c-sli__item">
            <span class="c-sli__label">维保单位：</span>
            <span class="c-sli__num">{{ xfqbqkInfo.wbdw }}</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">维保时间：</span>
            <span class="c-sli__num">{{ xfqbqkInfo.byrq | date("yyyy-MM-dd") }}</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">维护人员</span>
            <span class="c-sli__num">{{ xfqbqkInfo.whry }}</span>
          </li>
          <!-- <li class="c-sli__item">
            <span class="c-sli__label">维保内容：</span>
            <span class="c-sli__num">对自动喷水灭火系统/火灾警报系统进行定期维护保养</span>
          </li> -->
          <li class="c-sli__item">
            <span class="c-sli__label">检查日期：</span>
            <span class="c-sli__num">{{ xfqbqkInfo.jcsj | date("yyyy-MM-dd") }}</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">检查人</span>
            <span class="c-sli__num">{{ xfqbqkInfo.jcr }}</span>
          </li>
          <!-- <li class="c-sli__item">
            <span class="c-sli__label">检查类别：</span>
            <span class="c-sli__num">巡查</span>
          </li>
          <li class="c-sli__item">
            <span class="c-sli__label">检查结果：</span>
            <span class="c-sli__num">正常</span>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '../components/SubTitle.vue';
import { virtualService } from '../virtual.service';
export default {
  name: 'ChartBox',
  components: {
    SubTitle
  },
  data () {
    return {
      activeMonitoringRoom: '',
      // 监控室
      monitoringRoomList: [
        // {
        //   code: 'gzwzds',
        //   name: '广州物资大厦',
        //   yxsc: '1512.9'
        // },
        // {
        //   code: 'hm',
        //   name: '鸿蒙智慧消防实验室',
        //   yxsc: '926'
        // }
        /* {
          code: 'dd1',
          name: '待定'
        },
        {
          code: 'dd2',
          name: '待定'
        } */
      ],
      // 消防维保情况信息
      xfqbqkInfo: {},
      // 系统设备数据
      xtsbList: [
        // { xtMc: '火灾自动报警', zs: 320, zc: 308, yc: 4, lx: 8 },
        // { xtMc: '消防水系统', zs: 85, zc: 76, yc: 4, lx: 5 },
        // { xtMc: '电气火灾系统', zs: 77, zc: 71, yc: 0, lx: 6 },
        // { xtMc: '消火栓系统', zs: 92, zc: 90, yc: 0, lx: 2 },
        // { xtMc: '防火门及卷帘系统', zs: 392, zc: 390, yc: 0, lx: 2 },
        // { xtMc: '防烟排烟系统', zs: 192, zc: 190, yc: 0, lx: 2 },
        // { xtMc: '应急照明和疏散指示系统', zs: 1092, zc: 968, yc: 23, lx: 101 }

      ]
    };
  },

  methods: {

    init () {
      virtualService.queryJqrjk()
        .then(rsp => {
          this.monitoringRoomList = rsp.data;
          this.activeMonitoringRoom = rsp.data[0].code;
          // 定义key,value值，value为传递的参数
          this.$emit('getSonDate', this.activeMonitoringRoom);

          // 消防设备
          this.queryXfsb();
          // 消防维保情况
          this.queryXfwbqk();
        })
        .catch(err => {
          console.error('获取联网单位信息错误！', err);
        });
    },
    handOnChang (_code) {
      this.activeMonitoringRoom = _code;
      // localStorage.setItem('activeMonitoringRoom', this.activeMonitoringRoom);
      this.queryXfsb();

      this.$emit('getSonDate', this.activeMonitoringRoom);
    },
    queryXfsb () {
      virtualService.queryXfsb(this.activeMonitoringRoom)
        .then(rsp => {
          this.xtsbList = rsp.data;
        })
        .catch(err => {
          console.error('获取消防设备信息错误！', err);
        });
    },
    queryXfwbqk () {
      virtualService.queryXfwbqk(this.activeMonitoringRoom)
        .then(rsp => {
          this.xfqbqkInfo = rsp.data;
        })
        .catch(err => {
          console.error('获取消防维保情况信息错误！', err);
        });
    }

  },
  created () {
    this.init();
  }
};
</script>

<style lang="scss">
@import './chart.scss';
</style>
