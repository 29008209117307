<template>
  <div class="c-sinfo">
    <div class="c-sinfo__header">
      建筑物详情
    </div>
    <div class="c-sinfo__body">
      <div class="c-sinfo__detail">
        <ul class="c-sdt">
          <li class="c-sdt__item">
            <span class="c-sdt__label">单位名称：</span>
            <span class="c-sdt__content">{{ jzwInfo.dwmc }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">耐火等级：</span>
            <span class="c-sdt__content">{{ jzwInfo.nhdjMc }}</span>
          </li>
          <!-- <li class="c-sdt__item">
            <span class="c-sdt__label">火灾危险性：</span>
            <span class="c-sdt__content">一级耐火</span>
          </li> -->
          <li class="c-sdt__item">
            <span class="c-sdt__label">建造时期：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzrq }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建筑面积：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzmj }}㎡</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建造高度：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzgd }}米</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建筑层数：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzcs }}</span>
          </li>
        </ul>
        <ul class="c-sdt">
          <li class="c-sdt__item">
            <span class="c-sdt__label">消防安全管理员：</span>
            <span class="c-sdt__content">{{ jzwInfo.xfaqglrmc }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">安全管理员电话：</span>
            <span class="c-sdt__content">{{ jzwInfo.xfaqglrdh }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建（构）筑物类别：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzlbMc }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">避难层数量：</span>
            <span class="c-sdt__content">{{ jzwInfo.bncs }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">安全出口数量：</span>
            <span class="c-sdt__content">{{ jzwInfo.aqcks }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">消防电梯数量：</span>
            <span class="c-sdt__content">{{ jzwInfo.xfdts }}</span>
          </li>
        </ul>
      </div>
      <div class="c-sb-model">
        <span class="u-sub_title">建筑立体图</span>
        <div class="c-sb-model__inner">
          <img
            src="/images/screen/3dModel.png"
            alt="建筑立体图"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { virtualService } from '../virtual.service';
export default {
  name: 'BottomBox',
  props: {
    // 联网单位
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeMonitoringRoom: localStorage.getItem('activeMonitoringRoom'),
      // 建筑物概述信息
      jzwInfo: {}
    };
  },
  // computed: {

  // },
  methods: {
    queryJzw () {
      virtualService.queryJzw(this.code)
        .then(rsp => {
          this.jzwInfo = rsp.data;
        })
        .catch(err => {
          console.error('获取建筑物信息错误！', err);
        });
    }
  },
  watch: {
    code (newVal, oldVal) {
      this.queryJzw();
    }
  }
};
</script>

<style lang="scss">
.c-sinfo{
  background: url('/images/screen/bottom-box-bg.svg') no-repeat center top;
  background-size: cover;

  &__header{
    padding: 14px 0 26px;
    font-size: 22px;
    color: #FFF;
    text-align: center;
  }

  &__body{
    display: flex;
    justify-content: space-between;
    padding: 0 24px 0 20px;
  }
}

.u-sub_title{
  display: block;
  width: 182px;
  height: 20px;
  padding-left: 4px;
  margin-bottom: 12px;
  border-left: 4px solid #10B6FF;
  font-size: 14px;
  line-height: 20px;
  background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, rgba(0,152,255,0.45) 100%);
}

.c-sb-model__inner{
  width: 316px;
  height: 164px;
  border: 1px solid rgba(16,182,255,1);
  border-radius: 8px;

  img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
}

.c-sinfo__detail{
  display: flex;
  width: calc(100% - 316px);

  .c-sdt{
    width: 50%;
    padding-left: 10px;

    &__item{
      justify-content: flex-start;
    }

    &__label{
      flex-shrink: 0;
      width: 76px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: 400;
      color: #E2F3FF;
    }

    &:last-child .c-sdt__label{
      flex-shrink: 0;
      width: 130px;
    }

    &__content{
      padding-left: 16px;
      font-size: 14px;
    }
  }
}
</style>
