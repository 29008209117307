<template>
  <a-scene
    vr-mode-ui="enabled: false"
    inspector="url: /3DModel/js/aframe-inspector.min.js;"
    renderer="physicallyCorrectLights: true;colorManagement: true;"
  >
    <a-assets>
      <img
        id="sky"
        src="/3DModel/sky.jpg"
      >
      <a-asset-item
        id="office"
        src="/3DModel/HMOfficeSence4_np.gltf"
      />
      <a-asset-item
        id="gltf_officeFontWall"
        src="/3DModel/HMOfficeFontWall3.gltf"
      />
      <img
        id="officeLeftWall"
        src="/3DModel/scene_left.jpg"
      >
      <img
        id="officeRightWall"
        src="/3DModel/scene_right.jpg"
      >
      <a-asset-item
        id="robet"
        src="/3DModel/HMRobet10.gltf"
      />
      <a-asset-item
        id="table"
        src="/3DModel/HMTable3_p2.gltf"
      />
      <a-asset-item
        id="chair"
        src="/3DModel/HMChair2.gltf"
      />
      <a-asset-item
        id="STXingkaiFont"
        src="/3DModel/font/STXingkai.json"
      />
    </a-assets>

    <a-camera
      fov="50"
      near="0.005"
      far="10000"
      position="0 2 21.7"
    >
      <!-- look-controls="pointerLockEnabled: true;" -->
      <!-- 环境光 -->
      <a-light
        type="ambient"
        color="#FFFFFF"
        intensity="0.3"
      />

      <!-- 平行光 -->
      <a-light
        type="directional"
        color="#CCDDFF"
        intensity="3"
        position="0.5 0 0.866"
      />
    </a-camera>

    <a-sky
      src="#sky"
      rotation="0 -90 0"
    />

    <!-- 办公室 -->
    <a-gltf-model
      src="#office"
      scale="1 1 1"
      position="0 -0.72 0"
      rotation=""
    >
      <!-- 透明窗 -->
      <a-entity
        geometry="primitive: box; width:24; height:4.5; depth: 0.1; "
        material="color: 0X969292; transparent: true; opacity: 0.3;"
        position="0 3 -3.5"
      />

      <a-plane
        src="#officeLeftWall"
        height="2.6"
        width="13.8"
        rotation="0 90 0"
        position="-11.46, 2.4, 10.15"
      />

      <a-plane
        src="#officeRightWall"
        height="1.05"
        width="6.8"
        rotation="0 -90 0"
        position="11.29 2.544 5.7"
      />

      <a-plane
        src="#officeRightWall"
        height="1.05"
        width="6.8"
        rotation="0 -90 0"
        position="11.29 2.525 15.46"
        scale="1.2 1 1"
      />

      <!-- 顶部灯光*4 -->
      <a-entity
        v-for="x in 2"
        :key="'topLight_'+x"
      >
        <a-entity
          v-for="y in 2"
          :key="'topLight_'+x+y"
          area-light="intensity:5; width:8; height:8; color: #ccddff; showHelper: false; helperColor: #ccddff;"
          rotation="270 0 0"
          :position="((x-1)*(8+0.5)-8.5) + ' 6 ' + ((y-1)*(8+0.5)+6)"
        />
      </a-entity>

      <!-- 左墙灯光*2、右墙灯光*2 -->
      <a-entity
        v-for="x in 2"
        :key="'betweenLight_'+x"
      >
        <a-entity
          area-light="intensity:3; width:5; height:4.8; color: #ccddff; showHelper: false; helperColor: #ccddff;"
          :rotation="'0 '+ (x === 1 ? 90 : -90) + ' 0'"
          :position="(x === 1 ? -11 : 11) + ' 3.15 ' + (x === 1 ? 2.5 : -2.5)"
        />
        <a-entity
          area-light="intensity:3; width:20; height:4.8; color: #ccddff; showHelper: false; helperColor: #ccddff;"
          :rotation="'0 '+ (x === 1 ? 90 : -90) + ' 0'"
          :position="(x === 1 ? -11 : 11) + ' 3.15 ' + (x === 1 ? 22.8 : 2.7)"
        />
      </a-entity>

      <!-- 后墙灯光 -->
      <a-entity
        area-light="intensity:3; width:23.78; height:4.8; color: #ccddff; showHelper: false; helperColor: #ccddff;"
        rotation="0 180 0"
        position="11.8 3.15 23"
      />

      <!-- 左墙文字 -->
      <a-entity
        text-geometry="value: 智慧机器人平台; font: #STXingkaiFont; size: 11; height: 3; curveSegments: 12; bevelThickness: 0; bevelSize: 0; bevelEnabled: false; "
        position="-11.5 4.2 11.6"
        rotation="0 90 0"
        scale="0.02 0.02 0.02"
      />
      <!-- 右墙文字 -->
      <a-entity
        text-geometry="value: 智慧机器人平台; font: #STXingkaiFont; size: 13; height: 3; curveSegments: 12; bevelThickness: 0; bevelSize: 0; bevelEnabled: false; "
        position="11.45 4.05 8.3"
        rotation="0 -90 0"
        scale="0.02 0.02 0.02"
      />
      <!-- 后墙文字 -->
      <a-entity
        text-geometry="value: 智慧机器人平台; font: #STXingkaiFont; size: 23; height: 3; curveSegments: 12; bevelThickness: 0; bevelSize: 0; bevelEnabled: false; "
        position="2.85 2.73 43.5"
        rotation="0 180 0"
        scale="0.02 0.02 0.02"
      />
    </a-gltf-model>

    <!-- 坐着的机器人队列 -->
    <a-entity
      v-for="y in 4"
      :key="'sitRobet'+y"
    >
      <a-entity
        v-for="x in 5"
        :key="'sitRobet'+x+y"
        :position="(-4.5+(x-1)*2.1) + ' 0 ' + (3.5+(y-1)*3)"
        position11="-4.5 0 3.5"
        rotation="0 180 0"
      >
        <a-gltf-model
          class="sitRobet"
          src="#robet"
          scale="1 1 1"
          position="0 0 -0.7"
          rotation=""
          animation-mixer="clampWhenFinished: true; loop: once; duration: 0.1; clipDuration: 2000;"
        />
        <a-gltf-model
          src="#table"
          scale="1 1 1"
          position="0 0 0"
          rotation=""
        >
          <a-text
            value="消防值班"
            font="/3DModel/font/STXINGKA.TTF-msdf.json"
            color="#fff"
            negate="false"
            size="3.8"
            position="0.456 0.938 0.168"
            width="0.8"
          />
          <a-text
            value="消防值班"
            font="/3DModel/font/STXINGKA.TTF-msdf.json"
            color="#fff"
            negate="false"
            size="3.8"
            position="0.644 0.940 0.156"
            width="0.8"
            rotation="0 180 0"
          />
        </a-gltf-model>
        <a-gltf-model
          src="#chair"
          scale="1 1 1"
          position="0 0 -0.7"
          rotation=""
        />
      </a-entity>
    </a-entity>

    <a-gltf-model
      id="myRobet"
      src="#robet"
      scale="1 1 1"
      position="0 0 0"
      rotation="0 90 0"
      animation-mixer="clipDuration: 1063; "
      animation__walk1="property: position; easing: linear; from: -8 0 0; to: 8 0 0; dur: 8000; startEvents: model-loaded,animationcomplete__trun4"
      animation__trun1="property: rotation; from: 0 90 0; to: 0 0 0; dur: 300; startEvents: animationcomplete__walk1"
      animation__walk2="property: position; easing: linear; from: 8 0 0; to: 8 0 18; dur: 9000; startEvents: animationcomplete__trun1"
      animation__trun2="property: rotation; from: 0 0 0; to: 0 -90 0; dur: 300; startEvents: animationcomplete__walk2"
      animation__walk3="property: position; easing: linear; from: 8 0 18; to: -8 0 18; dur: 8000; startEvents: animationcomplete__trun2"
      animation__trun3="property: rotation; from: 0 270 0; to: 0 180 0; dur: 300; startEvents: animationcomplete__walk3"
      animation__walk4="property: position; easing: linear; from: -8 0 18; to: -8 0 0; dur: 9000; startEvents: animationcomplete__trun3"
      animation__trun4="property: rotation; from: 0 180 0; to: 0 90 0; dur: 300; startEvents: animationcomplete__walk4"
    />
  </a-scene>
</template>

<script>

export default {
  name: 'NplModel',
  data () {
    return {
      RobetLightColor: {
        // 红色：rgb（230,50,16）  #e63210
        // 黄色：rgb（230,140,17） #e68c11
        // 蓝色：rgb（0,180,255） #00b4ff
        // 灰色：rgb（142,142,168）#8e8ea8
        // eslint-disable-next-line no-undef
        red: new THREE.Color(0xe63210),
        // eslint-disable-next-line no-undef
        yellow: new THREE.Color(0xe68c11),
        // eslint-disable-next-line no-undef
        blue: new THREE.Color(0x00b4ff),
        // eslint-disable-next-line no-undef
        drak: new THREE.Color(0x8e8ea8)
      }
    };
  },
  beforeMount () {
    document.addEventListener('model-loaded', event => {
      const el = event.target;
      // 机器人
      if (el.className === 'sitRobet') {
        const material = el.object3D.children[0].children[0].children[1].children[3].material;
        material.color = this.RobetLightColor.red;// 设置胸口灯
      }
      if (el.id === 'myRobet') {
        const material = el.object3D.children[0].children[0].children[1].children[3].material;
        material.color = this.RobetLightColor.blue;// 设置胸口灯
      }
    });
  }

};
</script>
